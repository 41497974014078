import { AppTranslation } from '@/libs/i18n/interface';

import bi from './bi';
import mka from './mka';
import segment from './segment';
import component from './component';
import communication from './communication';
import authentication from './authentication';
import permission from './permission';
// for another available language, we will provide type for key auto-completion and type checking
// (and it will skip key that not defined, act as optional and will fallback to default language, as config in boot/i18n)
import myAccount from './my-account';
import notification from './notification';
import dataRelation from './data-relation';
import dataConnector from './data_connector';

export default <AppTranslation>{
  time_picker: {
    select_time: 'เลือกเวลา',
  },

  date_picker: {
    relative: {
      // -- duration
      day: '{days} วัน | {days} วัน',
      month: '{months} เดือน | {months} เดือน',
      year: '{years} ปี | {years} ปี',
      hour: '{hours} ชั่วโมง | {hours} ชั่วโมง',
      minute: '{minutes} นาที | {minutes} นาที',
      second: '{seconds} วินาที | {seconds} วินาที',
      // --- relative
      today: 'วันนี้',
      // --- custom
      custom: 'กำหนดเอง',
    },
    to: 'ถึง',
    date_range: 'ช่วงวันที่',
    select_date: 'เลือกวันที่',
    start_date: 'วันที่เริ่มต้น',
    end_date: 'วันที่สิ้นสุด',
    apply: 'ตกลง',
    cancel: 'ยกเลิก',
    relative_picker: 'ตัวเลือกวันที่แบบสัมพันธ์',
    last_day: '{num} วันที่ผ่านมา',
    last_month: '{num} เดือนที่ผ่านมา',
    last_year: '{num} ปีที่ผ่านมา',
    last_hour: '{num} ชั่วโมงที่ผ่านมา',
    last_minute: '{num} นาทีที่ผ่านมา',
    last_second: '{num} วินาทีที่ผ่านมา',
  },

  Segment: 'กลุ่มเป้าหมาย',
  Communication: 'การสื่อสาร​',
  Channel: 'ช่องทาง',
  'Line OA': 'LINE',
  campaign_list: 'รายการแคมเปญ',
  edit_campaign: 'แก้ไขแคมเปญ',
  Campaign: 'แคมเปญ',
  Create: 'สร้าง',
  campaign_info: 'รายละเอียดแคมเปญ',

  auth: {
    btn_login: 'เข้าสู่ระบบ',
    username: 'บัญชีผู้ใช้',
    email_required: 'กรุณาใส่อีเมล',
    username_required: 'กรุณาใส่บัญชีผู้ใช้',
    username_invalid: 'บัญชีผู้ใช้ผิด',
    password: 'รหัสผ่าน',
    password_required: 'กรุณาใส่รหัสผ่าน',
    password_invalid: 'รหัสผ่านผิด',
  },

  main_menu: {
    dashboard: 'แดชบอร์ด',
    segment: 'สร้างกลุ่มเป้าหมาย',
    data_connector: 'แหล่งเชื่อมต่อข้อมูล',
    data_cconnector_overview: 'แหล่งข้อมูล',
    data_connector_data_relationship: 'การจัดการความสัมพันธ์',
    data_connector_coupon: 'คูปอง',
    data_connector_history: 'ประวัติ',
    communication: 'การสื่อสาร',
    communication_all_channel: 'ช่องทางทั้งหมด',
    communication_limitation: 'ข้อจำกัด',
    automation: 'การตลาดอัตโนมัติ',
    setting: 'การตั้งค่า',
  },

  menu_bar: {
    edit_profile: 'ตั้งค่าโปรไฟล์',
    brand_account: 'บัญชีของฉัน',
    change_brand_account: 'เปลี่ยนแบรนด์',
    permission: 'สิทธิการใช้งาน',
    profile: 'โปรไฟล์',
    manage_permission: 'จัดการสิทธิ์การใช้งาน',
    history: 'ประวัติการใช้งาน',
    change_role: 'เปลี่ยนบทบาท',
    manage_storage: 'จัดการที่เก็บข้อมูล',
    configuration: 'กำหนดค่า',
    sign_out: 'ลงชื่อออก',
    change_password: 'เปลี่ยนรหัสผ่าน',
    switchlanguage: 'ภาษา',
  },

  change_brand_modal: {
    error: 'เกิดข้อผิดพลาด',
    error_description: 'ไม่สามารถเลือก Brand ได้ในขณะนี้',
    change_brand_account: 'เปลี่ยนแบรนด์',
    select_brand_account: 'เลือกแบรนด์',
    cancel: 'ยกเลิก',
    change: 'เปลี่ยน',
  },

  bi,

  data_connector: {
    // Tab
    tab_data_connector: 'แหล่งเชื่อมต่อข้อมูล​',
    tab_data_source_list: 'เชื่อมต่อข้อมูล',
    tab_add_datasource: 'เพิ่มแหล่งข้อมูล',
    tab_excel_datasource: 'แหล่งข้อมูล Excel/CSV',
    tab_file_history: 'ประวัติการอัพโหลดข้อมูล',
    tab_Setting_fasthelp5_Integration: 'การตั้งค่ารวม Fasthelp5',
    // Page overview
    overview_title: 'ฟังก์ชั่นจัดการเครื่องมือสำหรับนำเข้าและส่งออกข้อมูล', //'ฟังก์ชั่นจัดการเครื่องมือสำหรับนำเข้าและส่งออกข้อมูล เพื่อเพิ่มขีดความสามารถของระบบ CDP'
    overview_btn_connect_datasource: 'เชื่อมข้อมูล',
    overview_data_connector_detail: 'ตอนนี้คุณสามารถเชื่อมต่อปลายทางที่คุณต้องการส่งข้อมูลไป',
    overview_btn_connect_commu: 'จุดหมายปลายทาง​',
    overview_commu_detail: 'เพิ่มปลายทางที่คุณต้องการส่งออกข้อมูลหรือเผยแพร่แคมเปญที่นี่',
    // Page file history
    file_history_column_no: 'ลำดับ',
    file_history_column_ref_code: 'รหัสอ้างอิง',
    file_history_column_type: 'ประเภท',
    file_history_column_name: 'ชื่อ',
    file_history_column_amount: 'อัพโหลดไฟล์',
    file_history_column_last_update: 'แก้ไขล่าสุด',
    file_history_column_status: 'สถานะ',
    // Page datasource
    datasource_header: 'เพิ่มแหล่งข้อมูล',
    datasource_search_text: 'ค้นหา',
    // Page dataset
    dataset_btn_create_template: 'สร้าง',
    // Page create template
    // Page update file
    upload_file_progress_upload: 'อัปโหลด',
    upload_file_progress_confirm_data: 'ยืนยันข้อมูล',
    upload_title_download_template: 'ดาวน์โหลดแบบฟอร์ม​',
    upload_btn_download_template: 'ดาวน์โหลด',
    upload_btn_browse_file: 'อัปโหลดไฟล์',
    upload_backup_file: 'สำรองไฟล์',
    // Page summary upload
    summary_upload_column_status: 'สถานะ',
    summary_upload_column_quantity: 'จำนวน',
    summary_upload_column_row_success: 'สำเร็จ',
    summary_upload_column_row_error: 'เกิดข้อผิดพลาด',
    summary_upload_column_row_total: 'รวม',
    // Page coupon managements
    tab_coupon_management: 'คูปอง',
    tab_coupon_detail: 'รายละเอียด',
    upload_coupon_button: 'อัปโหลดข้อมูลทั้งหมด',
    edit_coupon_button: 'แก้ไขรายละเอียด',
    status_coupon_text: 'สถานะ',
    // Table coupon
    couponId: 'รหัสคูปอง',
    image: 'รูปภาพ',
    campaignName: 'ชื่อแคมเปญ',
    couponType: 'ประเภทคูปอง',
    trackingCode: 'จำนวนการใช้คูปอง/ ติดตามการใช้คูปอง',
    source: 'แหล่งข้อมูล',
    format: 'รูปแบบข้อมูล',
    balanceandtotal: 'ยอดคงเหลือ/ยอดรวม',
    balance: 'สมดุล',
    total: 'จำนวนทั้งหมด',
    reference: 'ข้อมูลอ้างอิง',
    lastUpload: 'ข้อมูลอัปเดตล่าสุด',
    updateBy: 'อัปเดตโดย',
    status: 'สถานะ',
    lastUpdate: 'อัปเดตล่าสุด',
    action_edit: 'แก้ไขการกระทำ',
  },

  setting: {
    unauthorized: 'ไม่ได้รับอนุญาต',
    detail_setting: '"คุณต้องได้รับอนุญาตจึงจะดำเนินการนี้ได้" บน Choco CDP',
    cell_us: 'โทร',
    contact: 'ติดต่อ',
  },

  base_table: {
    noItems: 'ไม่มีรายการ',
    loading: 'กำลังโหลด',
  },
  global: {
    view_more: 'ดูเพิ่มเติม',
    loading: 'กำลังโหลด...',
    export: 'ส่งออก',
    cancel: 'ยกเลิก',
    apply: 'นำมาใช้',
    close: 'ปิด',
    confirm: 'ยืนยัน',
    filter: 'ตัวกรอง',
    save: 'บันทึก',
    edit: 'แก้ไข',
    copy: 'คัดลอก',
    delete: 'ลบ',
    no_information: 'ไม่มีข้อมูล',
    error: 'เกิดข้อผิดพลาด',
    back: 'กลับ',
  },

  component: component,
  segment: segment,
  mka: mka,
  communication: communication,
  authentication: authentication,
  permission: permission,
  account: myAccount,
  notification: notification,
  dataRelation: dataRelation,
  dc: dataConnector,
  // Churn Rate​
  churn_rate: 'อัตราการเลิกเป็นลูกค้า',
  all_segment: 'กลุ่มเป้าหมายทั้งหมด​',
  overview: 'ภาพรวม​',
  data_source: 'แหล่งที่มาทั้งหมด',
  calculate_churn_rate: 'คำนวณอัตราการสูญเสียลูกค้า​',
  overall_churn_rate: 'ภาพรวมคำนวณอัตราการสูญเสียลูกค้า',
  des_overall_churn_rate: 'อัตราการสูญเสียลูกค้า คืออัตราส่วนของลูกค้าทั้งหมดที่ยกเลิกการใข้สินค้าหรือบริการของธุรกิจในช่วงเวลาหนึ่ง',
  select_segment: 'เลือกกลุ่มเป้าหมาย',
  stage: 'สถานะ',
  normal: 'ปกติ',
  risk_churn: 'เสี่ยงสูญเสีย',
  churn: 'สูญเสีย',
};
